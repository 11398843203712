import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { InvoicesPrint } from 'ui/component/orders-list/order-print';
import {
    ISalesLayoutConfig,
    salesLayoutConfigVar,
    currentOrderVar,
    invoiceIdVar,
} from '../../layouts/sales-layout/sales-layout-state';
import { findInvoiceById } from '../../util/process-order-entities';
import { SalesLayout } from '../../layouts/sales-layout';
import { OrderDetailsPageContent } from '../order-details-page-content';
import orderDetailsStyles from '../style.css';

const InvoicePrintPage: React.FC = () => {
    const [pageTitle, setPageTitle] = useState<string>('');

    const t = usePhraseTranslater();

    const config: ISalesLayoutConfig = useReactiveVar(salesLayoutConfigVar);
    const currentOrder = useReactiveVar(currentOrderVar);
    const invoiceId = useReactiveVar(invoiceIdVar);

    const invoicePrintPageComponent: JSX.Element = (
        <OrderDetailsPageContent>
            <InvoicesPrint config={config} order={currentOrder} invoiceId={invoiceId} />
        </OrderDetailsPageContent>
    );

    useEffect(() => {
        if (!currentOrder) {
            return;
        }

        const {
            invoices,
        } = currentOrder;

        const updatedPageTitle = invoiceId !== ''
            ? t('Print Invoice #%1', (findInvoiceById(invoices, invoiceId)?.number || ''))
            : t('Print All Invoices');
        setPageTitle(updatedPageTitle);
    }, [currentOrder]);

    return (
        <SalesLayout
            pageKey="invoice-print"
            pageTitle={pageTitle}
            pageContent={invoicePrintPageComponent}
            salesLayoutClassName={orderDetailsStyles.printPageLayout}
            showLeftSidebar={false}
            loadCart={false}
            loadCountries
            loadInvoices
            isGuestPage={false}
            splitOrderItems={false}
        />
    );
};

export { InvoicePrintPage as default };
